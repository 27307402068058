import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TableBuilder, { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ButtonBuilder, ModalLinkBuilder, FormOptionsBuilder, TilesBuilder, HoverOver, ModalButtonBuilder } from '../../../../../../../GlobalComponents/Builders';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, Card, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { Link, useParams } from 'react-router-dom';
import { H4 } from '../../../../../../../AbstractElements';
import { buildOptionsFromArray, showRelativeTime, showTimestamp } from '../../../../../../../GlobalComponents/Helpers';
import { ContentLoader } from '../../../../../../../Layout/Loader';
import CountUp from 'react-countup';
import { AccountContext } from '../../../../../../../GlobalComponents/Authentication/Accounts';

const DMTProgressDashboard = () => {

  document.title = 'Progress Dashboard'

  let { jobId } = useParams();

  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();

  const { partner, emulationActive, isGlobal } = useContext(AccountContext);
  const [isProcessing, setIsProcessing] = useState(true);
  const [jobData, setJobData] = useState(null)
  const [allRecords, setAllRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [jobProgress, setJobProgress] = useState([]);
  const [jobResources, setJobResources] = useState([]);
  const [renderResults, setRenderResults] = useState(false);
  const [recordCount, setRecordCount] = useState(0);
  const [status, setStatus] = useState(false);
  const [lastRan, setLastRan] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [BasicTab, setBasicTab] = useState('0');
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  var services = {
    'connectwise-cloud': 'ConnectWise Manage - Cloud',
    'connectwise-onprem': 'ConnectWise Manage - SQL',
    'servicenow': 'ServiceNow',
    'salesforce': 'SalesForce',
    'simplesat': 'SimpleSat',
    'csv': 'CSV',
    'autotask': 'Autotask',
    'dynamics': 'Dynamics365',
    'mavenlink': 'Mavenlink',
    'netsuite': 'NetSuite',
    'jira': 'Jira',
    'pandadoc': 'PandaDoc',
    'halo': 'Halo',
    'servicenow-ntt': 'SN - NTT',
    'shippo': 'Shippo'
  }

  const tiles = [
    {
      id: 1,
      icon: 'FilePlus',
      title: `Records ${jobData && jobData.is_sync ? 'Synced' : 'Migrated'}`,
      count: <CountUp end={recordCount} duration={3} />,
      backgroundIcon: 'CheckCircle',
    },
    {
      id: 2,
      icon: 'File',
      title: 'Errors',
      count: <CountUp end={status ? status.reduce((a, b) => a + b.error, 0) : 0} duration={2} />,
      backgroundIcon: 'AlertTriangle',
    },
    {
      id: 3,
      icon: 'Activity',
      title: 'Progress',
      content: status ? <ModalLinkBuilder
        title={`${jobData && jobData.is_sync ? 'Sync' : 'Migration'} Progress`}
        size='xl'
        customLabel={<span className='tile-counter'>{totalCount > 0 ? (recordCount * 100 / totalCount).toFixed(0) : 'N/A'}%</span>}
        onSubmit={false}
        onCancelLabel='Close'
        body={
          <Fragment>
            <TableBuilder
              data={status}
              processing={false}
              pagination={false}
              title=''
              columns={[
                {
                  name: 'Resource',
                  selector: (row) => row.name,
                  sortable: true,
                  center: true,
                },
                {
                  name: 'Staged',
                  selector: (row) => row.staged,
                  sortable: true,
                  center: true,
                },
                {
                  name: 'Queued',
                  selector: (row) => row.queued,
                  sortable: true,
                  center: true,
                },
                {
                  name: 'Error',
                  selector: (row) => <span style={{ color: 'red' }}>{row.error}</span>,
                  sortable: true,
                  center: true,
                },
                {
                  name: 'Complete',
                  selector: (row) => row.complete,
                  sortable: true,
                  center: true,
                },
                {
                  name: 'Progress',
                  selector: (row) => <span style={{ fontWeight: 600 }}>{(row.complete / row.total * 100).toFixed(0)}%</span>,
                  sortable: true,
                  center: true,
                }
              ]}
            />
          </Fragment>
        }
      /> : 'N/A',
      backgroundIcon: 'Activity'
    },
    {
      id: 4,
      icon: 'Clock',
      title: 'Last Ran',
      content: showRelativeTime(lastRan),
    }
  ]



  const getTileData = () => {
    ApiRequestBuilder('procedureCallPost', null, { procedure: 'get_dmt_record_status_count', input: { 1: jobId } }, { setIsProcessing }).then(function (results) {
      if (results.data) {
        setStatus(getProgress(results.data))

        // setTotalCount to the sum of all record_count from all records in results.data
        const count = results.data.reduce((a, b) => a + b.record_count, 0)
        setTotalCount(count)

        // setRecordCount to the sum of all record_count with complete status
        setRecordCount(results.data.reduce((a, b) => a + (b.status === 'complete' ? b.record_count : 0), 0))

        // setLastRan to the max of all record.last_updated from all records in results.data
        const lastRan = results.data.reduce((a, b) => a > b.last_updated ? a : b.last_updated, 0)
        setLastRan(lastRan * 1000)

        const tabs = {}
        tabs[0] = { title: 'none', resource: '', count: -1, filter: '' }

        for (let i = 0; i < results.data.length; i++) {
          let resource = results.data[i];
          if (tabs[resource.job_resource_id] === undefined) {
            tabs[resource.job_resource_id] = { title: resource.name, count: resource.record_count, filter: resource.job_resource_id, resource: `${resource.resource_id} - ${resource.name}` }
          } else {
            tabs[resource.job_resource_id].count += resource.record_count
          }
        }

        const resources = Object.values(tabs)
        setJobResources(resources)

        if (count > 5000) {
          ToastBuilder('warning', 'High Record Count Detected')
          setShowDisclaimer(true)
          handleFetchRecordsByParts(resources)
          return;
        }

        ApiRequestBuilder('procedureCallPost', null, { procedure: 'get_dmt_job_records', input: { 1: jobId } }).then(function (results) {
          if (results.data) {
            results.data = handleErrorMessages(results.data)
            setAllRecords(results.data)
            setRecords(results.data)
            // setJobResources()
            setRenderResults(true)
            ToastBuilder('success', `Found ${results.data.length} Records`)
          }
        })
      }
    })
    return true;
  }

  const getProgress = (data) => {
    /*
    return all status_count data such that each resource is an entry with staged, complete, error, queued and total props
    */
    let resources = {}
    for (let i = 0; i < data.length; i++) {
      let record = data[i]
      if (resources[record.resource_id] === undefined) {
        resources[record.resource_id] = {
          name: record.name,
          staged: 0,
          complete: 0,
          error: 0,
          queued: 0,
          total: 0
        }
      }
      resources[record.resource_id].total += record.record_count
      switch (record.status) {
        case 'staged':
          resources[record.resource_id].staged += record.record_count
          break;
        case 'complete':
          resources[record.resource_id].complete += record.record_count
          break;
        case 'error':
          resources[record.resource_id].error += record.record_count
          break;
        case 'queued':
          resources[record.resource_id].queued += record.record_count
          break;
      }
    }
    return Object.values(resources)
  }

  const handleErrorMessages = (data) => {
    for (let i = 0; i < data.length; i++) {
      try {
        if (data[i].error_message && data[i].error_message.error) {
          data[i].error_message.error.message = JSON.parse(data[i].error_message.error.message)
        }
      } catch (err) {
        console.log(err)
      }
    }
    return data
  }

  const getJobProgress = () => {
    ApiRequestBuilder('jobsJobIdProgressGet', { jobId })
      .then(function (results) {
        setJobProgress(results.data ? results.data : [])
      }
      )
  }


  const handleFetchRecordsByParts = (resources) => {
    // console.log('FETCHING RECORDS BY PARTS', jobResources)
  }

  const onRecordSearch = (searchQuery = {}) => {

    if (!searchQuery.job_resource_id) {
      ToastBuilder('error', 'Please Select a Resource')
      return
    }

    let input = { 1: parseInt(searchQuery.job_resource_id) };
    let procedure = 'get_dmt_job_resource_records';

    if (searchQuery.record_id && searchQuery.record_id !== '') {
      input[2] = parseInt(searchQuery.record_id)
      procedure = 'get_dmt_job_resource_record'
    }

    ApiRequestBuilder('procedureCallPost', null, { procedure, input }).then(function (results) {
      if (results.data && results.data.length > 0) {
        results.data = handleErrorMessages(results.data)

        if (searchQuery.recordStatus && searchQuery.recordStatus !== '') {
          results.data = results.data.filter(record => record.status === searchQuery.recordStatus)
        }

        setRecords(results.data)
        setAllRecords(results.data)
        setRenderResults(true)
        ToastBuilder('success', `Found ${results.data.length} Records`)
      } else {
        setAllRecords([])
        setRecords([])
        setRenderResults(false)
        ToastBuilder('info', 'No Records Found')
      }
    }).catch((err) => {
      console.log(err);
      ToastBuilder('error', 'Unable to Search Records')
    })
  }

  const displayError = (error) => {
    return <ModalLinkBuilder onSubmit={false} onCancelLabel={'Close'} label={<span style={{ color: 'red' }}>error</span>} title='Error Message' body={<pre>{JSON.stringify(error, null, 4)}</pre>} size='lg' />
  }

  const displayAction = (action, actionArgs) => {
    return <ModalLinkBuilder onSubmit={false} onCancelLabel={'Close'} label={<span style={{ textDecoration: 'underline' }}>{action}</span>} title='Action Args' body={<pre>{JSON.stringify(actionArgs, null, 4)}</pre>} size='lg' />
  }

  const displaySecondaryRequest = (request) => {
    let requestText = '';
    for (let req in request) {
      requestText += '\n    ' + request[req] + '    \n';
    }
    return requestText;
  }

  const handleStatusChange = (e) => {
    if (e.target.value === '') {
      setRecords(allRecords)
      return;
    }
    setRecords(allRecords.filter(record => record.status === e.target.value))
    setValue('recordStatus', e.target.value)
  }

  useEffect(() => {

    ApiRequestBuilder('dmtJobsJobIdGet', { jobId }).then(function (results) {
      if (results.data && (results.data.partner_id === partner.current || emulationActive || isGlobal)) {
        setJobData(results.data)
        getTileData()
        getJobProgress()
      } else {
        location.href = process.env.PUBLIC_URL + '/tools/data-migration'
      }
    })
  }, []);

  return (
    <Fragment>
      <ContentLoader isLoading={isProcessing} />
      <Row>
        <Container fluid={true}>


          <TilesBuilder data={tiles} />

          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <h4>Details</h4><br />
                  {jobId ? (<p><strong>Job Id : </strong>&emsp;{jobId}</p>) : ''}
                  {jobData && jobData.name ? (<p><strong>Name : </strong>&emsp;{jobData.name}</p>) : ''}
                  {jobData ? (<p><strong>Services : </strong>&emsp;{services[jobData.source_connection.service_code]} <em>&nbsp;to&nbsp;</em> {services[jobData.destination_connection.service_code]}</p>) : ''}
                  {/* {jobData && jobData.source_connection ? (<p><strong>Source : </strong>&emsp;{jobData.source_connection.name}</p>) : ''} */}
                  {jobData && jobData.destination_connection ? (<p><strong>Connections : </strong>&emsp;{jobData.source_connection.name} <em>&nbsp;to&nbsp;</em> <span style={{ color: (jobData.is_production ? 'red' : '') }}>{jobData.destination_connection.name}</span></p>) : ''}
                  {jobData && jobData.test_connection && jobData.test_connection.name ? (<p><strong>Test Connection: </strong><span style={{ color: (jobData.is_production ? '' : 'green') }}>&emsp;{jobData.test_connection.name}</span></p>) : ''}
                  {totalCount ? (<p><strong>Record count : </strong>&emsp;{totalCount}</p>) : ''}
                  <br />

                  <div className='text-end' style={{ paddingTop: 0, marginTop: 0 }}>

                    <ButtonBuilder label='Back to Job' className='btn-primary btn-outline-primary' onClick={() => { location.href = process.env.PUBLIC_URL + `/tools/data-migration/jobs/${jobId}` }} />

                  </div>


                </CardBody>
              </Card>
            </Col>

            {jobResources.length > 0 ?
              <Col xl="8">
                <Form id='search-records-form' className='card' onSubmit={handleSubmit(onRecordSearch)}>
                  <CardHeader className='pb-0'>
                    <H4 attrH4={{ className: 'card-title mb-0' }}>Search Record Criteria</H4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <RegisteredInputsBuilder
                        registrator={register}
                        registratorErrors={errors}
                        inputs={[
                          {
                            label: 'Resource',
                            required: true,
                            name: 'job_resource_id',
                            type: 'select',
                            options: <FormOptionsBuilder options={buildOptionsFromArray(jobResources, { label: 'resource', value: 'filter' })} />,
                            required: false,
                            size: 4
                          },
                          {
                            label: 'Record ID',
                            name: 'record_id',
                            helpText: 'Source or Destination record',
                            size: 4
                          },
                          {
                            label: 'Status',
                            type: 'select',
                            name: 'recordStatus',
                            //  'staged', 'queued', 'complete', 'deleted', 'error', 'missing-mapping?'
                            options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'staged', 'queued', 'complete', 'deleted', 'error', 'missing-mapping'], { labelIsValue: true })} />,
                            helpText: 'Filter Search Results below',
                            size: 3,
                            onChange: (e) => handleStatusChange(e)
                          },
                        ]}
                      />
                    </Row>
                  </CardBody>
                  <CardFooter style={{ padding: 15, display: 'flex', alignItems: 'center' }}>
                    <div className='text-start' style={{ paddingLeft: 15, paddingBottom: 5, paddingTop: 5, width: '70%' }} >
                      {showDisclaimer ? <p>Due to the large amount of records found for this job, we can only display a portion of the most recent entries for each resource. Please use the Search Tool to find a specific record.</p> : null}
                    </div>

                    <div className='text-end' style={{ width: '30%', paddingRight: 5 }} >
                      <ButtonBuilder label='Search Records' />
                      <ButtonBuilder form='' label='Reset' className='btn-secondary m-2' onClick={() => { reset() }} />
                    </div>
                  </CardFooter>
                </Form></Col> : null}

          </Row>
          {/* { renderResults ?  */}
          <Col xl="12">
            <Card>
              <CardBody>
                <Nav tabs>
                  {jobResources.filter(tab => tab.title !== 'none').map((tab, i) =>
                    <NavItem key={tab.title}>
                      <NavLink href="#" className={BasicTab === String(i) ? 'active' : ''} onClick={() => { setBasicTab(String(i)) }}>{tab.title}</NavLink>
                    </NavItem>
                  )}
                </Nav>


                <TabContent activeTab={BasicTab}>
                  {jobResources.filter(tab => tab.title !== 'none').map((tab, i) =>
                    <TabPane className="fade show" tabId={String(i)} key={tab.title + i}>
                      <TableBuilder
                        subHeader={true}
                        columnsToSearch={['name', 'source_rec_id', 'destination_resource_rec_id', 'status', 'action', 'secondary_requests']}
                        title='Search Results'
                        data={records.filter(record => record.job_resource_id === tab.filter)}
                        processing={false}
                        columns={[
                          {
                            name: 'Rec Preview',
                            selector: (row) => <ModalLinkBuilder onSubmit={false} onCancelLabel={'Close'} label={<span style={{ textDecoration: 'underline' }}>{row.source_rec_id}</span>} title='Record Data' body={<pre>{JSON.stringify(row.resource_data, null, 4)}</pre>} size='xl' />,
                            // selector: (row) => row.source_rec_id,
                            sortable: true,
                            center: true,
                          },
                          {
                            name: 'Action',
                            selector: (row) => row.action ? row.action_args ? displayAction(row.action, row.action_args) : row.action : '-',
                            sortable: true,
                            center: true,
                          },
                          {
                            name: 'Dest. Rec ID',
                            selector: (row) => row.destination_resource_rec_id ? row.destination_resource_rec_id : '-',
                            sortable: true,
                            center: true,
                          },
                          {
                            name: 'Status',
                            selector: (row) => row.status === 'error' ? displayError(row.error_message) : row.status,
                            sortable: true,
                            center: true,
                          },
                          {
                            name: 'Requests',
                            selector: (row) => row.secondary_requests && row.secondary_requests.length > 0 ? <HoverOver content={displaySecondaryRequest(row.secondary_requests)} text={`${row.secondary_requests.length} requests`} /> : '-',
                            sortable: true,
                            center: true,
                          },
                          {
                            name: 'Updated',
                            selector: (row) => showTimestamp(row.updated_at * 1000),
                            sortable: true,
                            center: true,
                          },
                        ]}
                      />
                    </TabPane>
                  )}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          {/* : null} */}
        </Container>
      </Row>
    </Fragment>
  );
};

export default DMTProgressDashboard;