import React, { Fragment, useContext, useEffect, useState } from 'react';
import TableBuilder, {ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ToastBuilder, ButtonBuilder } from '../../../../../../GlobalComponents/Builders';
import { buildOptionsFromArray } from '../../../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, CardFooter} from 'reactstrap'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../../../../../GlobalComponents/Authentication/Accounts';
import parse from 'html-react-parser'
import { Col } from 'react-bootstrap';

const ActivationReport = () => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [salesReps, setSalesReps] = useState([]);
    const [searchQuery, setSearchQuery] = useState({});
    const [searchResults, setSearchResults] = useState([]);

    const searchActivations = (formData) => {
        setSearchQuery({})
        setSearchResults([])

        ApiRequestBuilder('oepActivationSearchPost', null, formData)
            .then(function(results) {
                if (results.data && results.data.length > 0)
                {
                    setSearchQuery(formData) // We'll store the query to pass to the POST handler if the user wants an export
                    setSearchResults(results.data)
                    ToastBuilder('success', 'Record Search Successful')
                } else ToastBuilder('info', 'No Records Matching Search')

            }
        )
    } 

    const salesRepCall = () => {
        ApiRequestBuilder('oepOrdersSalesrepsGet').then(function (reps) {
            setSalesReps([''].concat(reps.data))
        }
    )}

    useEffect(() => {
        salesRepCall()
    },[]);

    return (
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardBody>
                        <h4>Activation Report</h4>
                        { salesReps.length > 0 ? 
                            <Form id='search-activations-form' className='card' onSubmit={handleSubmit((data) => searchActivations(data))}>
                                <CardBody>
                                    <Row>
                                    <RegisteredInputsBuilder
                                        registrator={register}
                                        registratorErrors={errors}
                                        inputs={[
                                            {
                                                label: 'From',
                                                type: 'date', 
                                                name: 'searchFrom',
                                                helpText: 'Activation Date Start'
                                            },
                                            {
                                                label: 'To',
                                                type: 'date', 
                                                name: 'searchTo',
                                                helpText: 'Activation Date End'
                                            },
                                            {
                                                label: 'Sales Rep',
                                                type: 'select',
                                                required: false,
                                                options: <FormOptionsBuilder options={buildOptionsFromArray(salesReps, { labelIsValue: true })}/>,
                                                name: 'salesRep'
                                            },
                                            {
                                                label: 'Company',
                                                name: 'company',
                                            },
                                            {
                                                label: 'Carrier',
                                                type: 'select',
                                                required: false,
                                                options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'AT&T', 'T-Mobile', 'Verizon'], { labelIsValue: true })}/>,
                                                name: 'carrier'
                                            }
                                        ]}/>
                                    
                                    </Row>
                                </CardBody>
                                <CardFooter className='text-end'>
                                    <ButtonBuilder form='search-activations-form' label='Search' className='btn-primary m-2' />
                                    <ButtonBuilder form='' label='Reset' className='btn-secondary m-1' onClick={() => { 
                                        setValue('salesRep', '')
                                        setValue('searchFrom', '')
                                        setValue('company', '')
                                        setValue('carrier', '')
                                        setValue('searchTo', '')
                                    }}/>
                                </CardFooter>
                            </Form> : null}
                    </CardBody>
                </Card>
            </Col>
            { searchResults.length > 0 ? 
                <Card>
                    <CardBody>
                        <TableBuilder
                            data = {searchResults}
                            processing = {false}
                            subHeader={true}
                            title='Search Results'
                            columnsToSearch = {['order_number', 'company_name', 'carrier', 'sales_rep', 'order_status']}
                            actions={
                                <Fragment>
                                    <ButtonBuilder label={'Export'} className='btn-primary m-1' onClick={() => handleJobAction('download-o-template', (data) => handleCSVDownload(data, 'commission_search_export'), searchQuery)}/>
                                </Fragment>
                            }
                            columns = {[
                                {
                                     name: 'Order',
                                     selector: (row) => row.order_number,
                                     sortable: true,
                                     center: true,
                                     maxWidth: '3%'
                                },
                                {
                                    name: 'Telephone',
                                    selector: (row) => row.phone_number,
                                    sortable: true
                                },
                                {
                                    name: 'Company',
                                    selector: (row) => row.company_name,
                                    sortable: true
                                },
                                {
                                    name: 'Carrier',
                                    selector: (row) => row.carrier,
                                    sortable: true
                                },
                                {
                                    name: 'Sales Rep',
                                    selector: (row) => row.sales_rep,
                                    sortable: true
                                },
                                {
                                    name: 'Client Status',
                                    selector: (row) => row.client_status,
                                    sortable: true
                                },
                                {
                                    name: 'Commission Amount',
                                    selector: (row) => row.commission_amount,
                                    sortable: true
                                },
                                {
                                    name: 'Imei',
                                    selector: (row) => row.imei,
                                    sortable: true
                                },
                                {
                                    name: 'make',
                                    selector: (row) => row.make,
                                    sortable: true
                                },
                                {
                                    name: 'sim',
                                    selector: (row) => row.sim,
                                    sortable: true
                                },
                                {
                                    name: 'rate_plan',
                                    selector: (row) => row.rate_plan,
                                    sortable: true
                                },
                                {
                                    name: 'contract',
                                    selector: (row) => row.contract,
                                    sortable: true
                                },
                                {
                                    name: 'Activation Date',
                                    selector: (row) => row.activation_date,
                                    sortable: true
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
        : null}
        </Fragment>
    )
};

export default ActivationReport;