import React, { Fragment, useState, useEffect, useContext } from 'react';
import TableBuilder, { ApiRequestBuilder, ButtonBuilder, ModalButtonBuilder, RegisteredInputsBuilder, ToastBuilder } from '../../GlobalComponents/Builders';
import { Container, Row, Card, CardBody, Form } from 'reactstrap'
import { AccountContext } from '../../GlobalComponents/Authentication/Accounts';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ContentLoader } from '../../Layout/Loader';

const PartnerList = () => {

    const { getSession, userSession } = useContext(AccountContext);

    const [partnerData, setPartnerData] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handlePartnerCreate = (newPartnerData) => {
        if (newPartnerData.name !== '') {
            ApiRequestBuilder('partnersPost', null, newPartnerData).then(function (results) {
                
                if (results.data)
                {
                    ToastBuilder('success', 'Partner Creation Successful')
                    window.location.href = process.env.PUBLIC_URL + '/partners/' + String(results.data)
                }
                else {
                    ToastBuilder('error', 'Unable to Create Partner')
                }
            }).catch((err) => {
                ToastBuilder('error', 'Unable to Create Partner')
            })
        } else {
            ToastBuilder('warn', 'Partner name cannot be blank.')
        }
    }

    const getPartners = () => {
        ApiRequestBuilder('partnersGet', null, null, { setIsProcessing })
            .then(function (results) {
                setPartnerData(results.data && results.data.length > 0 ? results.data : [])
            })
    }

    useEffect(() => {

        document.title = 'View Partners'

        getPartners()
    }, []);

    return (
        <Fragment>
            <ContentLoader isLoading={isProcessing} />
            <Row>
                <Container>
                    <Card>
                        <CardBody>
                            <TableBuilder
                                subHeader={true}
                                columnsToSearch={['name']}
                                data={partnerData}
                                processing={isProcessing}
                                actions={<ModalButtonBuilder title='Create partner' className='btn-secondary'
                                    body={
                                        <Form id='create-partner-form' onSubmit={handleSubmit(handlePartnerCreate)}>
                                            <RegisteredInputsBuilder
                                                registrator={register}
                                                registratorErrors={errors}
                                                inputs={[
                                                    {
                                                        label: 'Name',
                                                        size: 12,
                                                        required: false,
                                                        placeholder: 'Please enter Partner name',
                                                        name: 'name'
                                                    },
                                                    {
                                                        label: 'Domain',
                                                        size: 12,
                                                        required: false,
                                                        placeholder: 'company-site.com',
                                                        name: 'domain'
                                                    }
                                                ]} />
                                        </Form>
                                    }
                                />}
                                title='Partners'
                                columns={[
                                    {
                                        name: 'ID',
                                        selector: (row) => row.partner_id,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: 'Profile',
                                        selector: (row) =>
                                            <Link to={`${process.env.PUBLIC_URL}/partners/` + row.partner_id}>View</Link>
                                        ,
                                        sortable: true,
                                        center: true,
                                    },
                                    {
                                        name: '',
                                        selector: (row) =>
                                            <ButtonBuilder label='Enter Partner Portal' className='m-1' onClick={() =>
                                                getSession().then(({ user }) => {
                                                    const attributes = [
                                                        new CognitoUserAttribute({ Name: "custom:partnerId", Value: String(row.partner_id) }),
                                                        new CognitoUserAttribute({ Name: "custom:emulationActive", Value: 'true' }),
                                                    ];

                                                    user.updateAttributes(attributes, (err, result) => {
                                                        if (err) console.error(err);
                                                        localStorage.setItem('partnerName', row.name)
                                                        window.location.href = process.env.PUBLIC_URL
                                                    });
                                                })}
                                            />
                                        ,
                                        sortable: true,
                                        center: true,
                                    }
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        </Fragment>
    );
};

export default PartnerList;