import React, { Fragment, useContext, useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import TableBuilder, { RegisteredInputsBuilder, ApiRequestBuilder, ToastBuilder, ButtonBuilder, ModalLinkBuilder, FormOptionsBuilder, HoverOver } from '../../../../../../../GlobalComponents/Builders';
import { Row, Col, CardHeader, CardBody, CardFooter, Form, Card } from 'reactstrap'
import { H4 } from '../../../../../../../AbstractElements';
import { buildOptionsFromArray, showTimestamp } from '../../../../../../../GlobalComponents/Helpers';

const DMTSearch = () => {

  document.title = 'Search Records'

  const { register: register1, handleSubmit: handleSubmit1, setValue: setValue1, formState: { errors: errors1 } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, setValue: setValue2, formState: { errors: errors2 } } = useForm();

  const [jobId, setJobId] = useState(null)
  const [allJobs, setAllJobs] = useState([])
  const [jobData, setJobData] = useState(null)
  const [allRecords, setAllRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [resources, setResources] = useState(null);
  const [renderResults, setRenderResults] = useState(false);

  const getResources = (data) => {

    setRenderResults(false);
    setResources(null);
    setJobId(data.jobId)

    let temp = allJobs.filter(job => job.job_id === parseInt(data.jobId))[0]
    setJobData(temp)

    ApiRequestBuilder('procedureCallPost', null, { procedure: 'get_dmt_record_status_count', input: { 1: data.jobId } }).then(function (results) {
      if (results.data && results.data.length > 0) {
        const tabs = {}
        tabs[0] = { title: 'none', resource: '', count: -1, filter: '' }

        for (let i = 0; i < results.data.length; i++) {
          let resource = results.data[i];
          if (tabs[resource.job_resource_id] === undefined) {
            tabs[resource.job_resource_id] = {
              title: resource.name,
              count: resource.record_count,
              filter: resource.job_resource_id,
              resource: `${resource.resource_id} - ${resource.name}`
            }
          } else {
            tabs[resource.job_resource_id].count += resource.record_count
          }
        }

        setResources(Object.values(tabs))

      } else {
        ToastBuilder('info', 'No Resources Found')
      }
    })
  }

  const handleErrorMessages = (data) => {
    for (let i = 0; i < data.length; i++) {
      try {
        if (data[i].error_message && data[i].error_message.error) {
          data[i].error_message.error.message = JSON.parse(data[i].error_message.error.message)
        }
      } catch (err) {
        console.log(err)
      }
    }
    return data
  }

  const onRecordSearch = (searchQuery) => {

    if (searchQuery.resource === '') {
      ToastBuilder('info', 'No Resource Selected')
      return
    }

    let input = {}
    input[1] = parseInt(searchQuery.resource)
    let procedure = 'get_dmt_job_resource_records'

    if (searchQuery.record_id !== '') {
      input[2] = parseInt(searchQuery.record_id)
      procedure = 'get_dmt_job_resource_record'
    }

    ApiRequestBuilder('procedureCallPost', null, { procedure, input }).then(function (results) {
      if (results.data && results.data.length > 0) {
        results.data = handleErrorMessages(results.data)
        // results.data.sort((a, b) => a.status === 'error' ? -1 : 1)

        if (searchQuery.status !== '') {
          results.data = results.data.filter(record => record.status === searchQuery.recordStatus)
        }

        setAllRecords(results.data)
        setRecords(results.data)
        setRenderResults(true)
        ToastBuilder('success', `Found ${results.data.length} Records`)
      } else {
        setAllRecords([])
        setRecords([])
        setRenderResults(false)
        ToastBuilder('info', 'No Records Found')
      }
    }).catch((err) => {
      console.log(err);
      ToastBuilder('error', 'Unable to Search Records')
    })
  }

  const displayError = (error) => {
    return <ModalLinkBuilder onSubmit={false} onCancelLabel={'Close'} label={<span style={{ color: 'red' }}>error</span>} title='Error Message' body={<pre>{JSON.stringify(error, null, 4)}</pre>} size='lg' />
  }

  const displayAction = (action, actionArgs) => {
    return <ModalLinkBuilder onSubmit={false} onCancelLabel={'Close'} label={<span style={{ textDecoration: 'underline' }}>{action}</span>} title='Action Args' body={<pre>{JSON.stringify(actionArgs, null, 4)}</pre>} size='lg' />
  }

  const displaySecondaryRequest = (request) => {
    let requestText = '';
    for (let req in request) {
      requestText += '\n    ' + request[req] + '    \n';
    }
    return requestText;
  }

  const handleStatusChange = (e) => {
    setValue2('status', e.target.value)
    if (e.target.value === '') {
      setRecords(allRecords)
      return;
    }
    setRecords(allRecords.filter(record => record.status === e.target.value))
  }

  useEffect(() => {

    ApiRequestBuilder('dmtJobsGet', null, null, null, { 'conditions': 'tool_id = 7' }).then(function (results) {
      if (results.data) {
        for (let i = 0; i < results.data.length; i++) {
          results.data[i].label = `${results.data[i].job_id} - ${results.data[i].name}`
        }
        setAllJobs(results.data)
      }
    })

  }, []);

  return (
    <Fragment>
      <Row>
        <Col xl="4">
          <Form id='search-job' className='card' onSubmit={handleSubmit1(getResources)}>
            <CardHeader className='pb-0'>
              <H4 attrH4={{ className: 'card-title mb-0' }}>Job Selection</H4>
            </CardHeader>
            <CardBody>
              <RegisteredInputsBuilder
                registrator={register1}
                registratorErrors={errors1}
                inputs={[
                  {
                    label: 'Job',
                    required: true,
                    name: 'jobId',
                    type: 'select',
                    options: <FormOptionsBuilder options={buildOptionsFromArray(allJobs, { label: 'label', value: 'job_id' })} />,
                    size: 12,
                    labelSize: 3
                  }
                ]} />
            </CardBody>
            <CardFooter className={'text-end'} style={{ padding: 15 }}>
              <ButtonBuilder form='search-job' label='Get Job Resources' className='btn btn-primary' />
            </CardFooter>
          </Form>
        </Col>
        <Col xl="8">
          <Card>
            <CardBody>
              <h4>Details</h4><br />
              {jobData && jobData.job_id ? (<p><strong>Job : </strong>&emsp;{jobData.job_id}</p>) : ''}
              {jobData && jobData.name ? (<p><strong>Name : </strong>&emsp;{jobData.name}</p>) : ''}
              {jobData && jobData.source_connection ? (<p><strong>Source : </strong>&emsp;{jobData.source_connection.name}</p>) : ''}
              {jobData && jobData.destination_connection ? (<p><strong>Destination : </strong><span style={{ color: 'red' }}>&emsp;{jobData.destination_connection.name}</span></p>) : ''}
              {jobData && jobData.test_connection && jobData.test_connection.name ? (<p><strong>Test Connection: </strong><span style={{ color: (jobData.is_production ? '' : 'green') }}>&emsp;{jobData.test_connection.name}</span></p>) : ''}
              {/* {totalCount ? (<p><strong>Record count : </strong>&emsp;{totalCount}</p>) : ''} */}

            </CardBody>
          </Card>
        </Col>
      </Row>

      {resources ?
        <Col xl="12">
          <Form id='search-records-form' className='card' onSubmit={handleSubmit2(onRecordSearch)}>
            <CardHeader className='pb-0'>
              <H4 attrH4={{ className: 'card-title mb-0' }}>Search Record Criteria</H4>
            </CardHeader>
            <CardBody>
              <Row>
                <RegisteredInputsBuilder
                  registrator={register2}
                  registratorErrors={errors2}
                  inputs={[
                    {
                      label: 'Resource',
                      required: true,
                      name: 'resource',
                      type: 'select',
                      options: <FormOptionsBuilder options={buildOptionsFromArray(resources, { label: 'resource', value: 'filter' })} />,
                      required: false,
                      size: 4,
                      labelSize: 4,
                      inputSize: 6
                    },
                    {
                      label: 'Record ID',
                      name: 'record_id',
                      size: 3,
                      labelSize: 4,
                      inputSize: 4
                    },
                    {
                      label: 'Status',
                      type: 'select',
                      name: 'status',
                      options: <FormOptionsBuilder options={buildOptionsFromArray(['', 'staged', 'queued', 'complete', 'deleted', 'error', 'missing-mapping'], { labelIsValue: true })} />,
                      onChange: (e) => handleStatusChange(e),
                      helpText: 'Find records in a specific status',
                      size: 3,
                      labelSize: 3,
                      inputSize: 7

                    },
                  ]}
                />
              </Row>
            </CardBody>
            <CardFooter className='text-end' style={{ padding: 0 }}>
              <ButtonBuilder label='Search Records' />
              <ButtonBuilder form='' label='Reset' className='btn-secondary m-3' onClick={() => document.getElementById("search-records-form").reset()} />
            </CardFooter>
          </Form></Col> : null}
      {renderResults ?
        <Col xl="12">
          <Card>
            <CardBody>
              <TableBuilder
                subHeader={true}
                columnsToSearch={['name', 'source_rec_id', 'destination_resource_rec_id', 'status', 'action', 'secondary_requests']}
                title='Search Results'
                data={records}
                processing={false}
                columns={[
                  {
                    name: 'Rec Preview',
                    selector: (row) => <ModalLinkBuilder onSubmit={false} onCancelLabel={'Close'} label={<span style={{ textDecoration: 'underline' }}>{row.source_rec_id}</span>} title='Record Data' body={<pre>{JSON.stringify(row.resource_data, null, 4)}</pre>} size='xl' />,
                    sortable: true,
                    center: true,
                  },
                  {
                    name: 'Action',
                    selector: (row) => row.action ? row.action_args ? displayAction(row.action, row.action_args) : row.action : '-',
                    sortable: true,
                    center: true,
                  },
                  {
                    name: 'Dest. Rec ID',
                    selector: (row) => row.destination_resource_rec_id ? row.destination_resource_rec_id : '-',
                    sortable: true,
                    center: true,
                  },
                  {
                    name: 'Status',
                    selector: (row) => row.status === 'error' ? displayError(row.error_message) : row.status,
                    sortable: true,
                    center: true,
                  },
                  // {
                  //     name: 'Status2',
                  //     selector: (row) => row.status === 'error' ? <HoverOver callback={displayError} callbackContent={row.error_message} content={JSON.stringify(row.error_message)} text={''}/>: row.status,
                  //     sortable: true,
                  //     center: true,
                  // },
                  {
                    name: 'Requests',
                    selector: (row) => row.secondary_requests && row.secondary_requests.length > 0 ? <HoverOver content={displaySecondaryRequest(row.secondary_requests)} text={`${row.secondary_requests.length} requests`} /> : '-',
                    sortable: true,
                    center: true,
                  },
                  {
                    name: 'Updated',
                    selector: (row) => showTimestamp(row.updated_at * 1000),
                    sortable: true,
                    center: true,
                  },
                ]}
              />
            </CardBody>
          </Card>
        </Col> : null}
    </Fragment>
  );
};

export default DMTSearch;
