import React, { createContext, useState, useRef } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import Pool from './UserPool'
import { ToastBuilder, ApiRequestBuilder } from '../Builders';

const AccountContext = createContext();

const Account = (props) => {

  var [ userSession, setUserSession ] = useState({})
  const [ emulationActive, setEmulationActive ] = useState(false)
  const [ isGlobal, setIsGlobal ] = useState(false)
  const dmtAccess = useRef(0)
  const partner = useRef(0)

  const getSession = async () => {
    const resolvedSession = await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject()
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err)
                } else {
                  const results = {}

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute
                    results[Name] = Value
                  }

                  resolve(results)
                }
              })
            })

            const token = session.getIdToken().getJwtToken()

            /**
             * Now that we have an API token, let's make a call to API Gateway to get
             * more information about this user's environment.
             */
            localStorage.setItem('apiToken', token)

            /**
             * Set default color schemes
             */
            localStorage.setItem('default_color', '#9c9b9a')
            localStorage.setItem('secondary_color', '#009ad4')
            
            document.getElementById("favicon").href = "https://s3.amazonaws.com/portal.nexnow.io/NexNow+logo+square.jpg"

            /**
             * ApiRequestBuilder('partnersPartnerIdGet', { partnerId : attributes['custom:partnerId'] }).then(function(results) { 
                 localStorage.setItem('default_color', results.data[0].default_color)
                 localStorage.setItem('secondary_color', results.data[0].secondary_color)
                 document.getElementById("favicon").href = results.data[0].favicon_url
                attributes.partner_logo = results.data[0].logo_url
             })
             */
            /**
             * Here we determine what level of access the current user has
             */
            attributes.access_level = attributes['custom:partnerId'] === '0' ? 'global' : attributes['custom:clientId'] === '0' ? 'partner' : 'client'
            attributes.portal_emulation_active = attributes['custom:emulationActive']

            /**
             * Until we determine the best way to access the session context while
             * creating the menu items, we need to keep things consistent and just
             * pass this value into localstorage
             */
            localStorage.setItem('accessLevel', attributes.access_level)

            setEmulationActive(attributes.portal_emulation_active === 'true');
            setIsGlobal(attributes.access_level === 'global');
            partner.current = parseInt(attributes['custom:partnerId']);

            setUserSession(attributes)
            
            resolve({
              user,
              headers: {
                Authorization: token,
              },
              ...session,
              ...attributes,
            })
          }
        })
      } else {
        reject()
      }
    })

    // if (resolvedSession['custom:emulationActive'] === 'true') return await resolvedSession;

    return await ApiRequestBuilder('subscriptionsGet', null, null, null, { conditions: 'tool_id = 7' })
      .then((results) => {
        if (results.data && results.data.length > 0 ) {
          let subs = results.data.map((sub) => sub.partner_id)
          if (subs.includes(partner.current)) {
            dmtAccess.current = 1;
          } else {
            dmtAccess.current = 0;
          }
        } else {
          dmtAccess.current = 0;
        }
        return resolvedSession;
      });
  };


  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })
      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          /*
              Before mfa workflow is set up, this is default event.
              Code below should yield attributes since user is authenticated at this point
              user.getUserAttributes((err,attributes) => { })
          */
          resolve({ status: 'authenticated', user: user })
        },

        onFailure: (err) => {
          switch (err.name) {
              case 'UserNotConfirmedException':
                  console.log(err)
                  ToastBuilder('info', 'Please Check Your Email to Verify Your Account')
              break;
              case 'NotAuthorizedException':
                  console.log(err)
                  ToastBuilder('error', 'Incorrect username or password.')
              break;
              default:
                  console.log(err)
                  ToastBuilder('info', 'Unknown Error on Log In')
              break;
          }
          reject(err)
        },

        newPasswordRequired: (data) => {
          ToastBuilder('warn', 'You Need to Reset Your Password')
          resolve(data)
        },

        mfaRequired: function (codeDeliveryDetails) {
          /*
              This event should never be emmited unless MFA is set to REQUIRED instead of optional
              Then I must redirect to request to frontend mfa page
          */
        },

        totpRequired: function (challengeName, challengeParameters) {
          /*
              Once mfa workflow has been set up, set prefered auth SOFTWARE TOKEN and mfa_secret stored in db,
              this is the default event emitted after successful login with email and password
          */
          resolve({ status: 'totpRequired', user: user })
        }
      })
    })


  const logout = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      user.signOut()
    }
  }

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        userSession,
        logout,
        emulationActive,
        isGlobal,
        dmtAccess,
        partner
      }}
    >
      {props.children}
    </AccountContext.Provider>
  )
}

export { Account, AccountContext }