import { Home, Users, Clipboard, HelpCircle, Zap, Briefcase, GitBranch, Link2, Calendar, TrendingUp, Tool } from 'react-feather';
import { useContext, useEffect } from 'react';
import { AccountContext } from '../../GlobalComponents/Authentication/Accounts';

export const buildMenuItems = () => {

    const { emulationActive, isGlobal, dmtAccess } = useContext(AccountContext)

    let Items = [
        {
            title: 'Calendar', icon: Calendar, type: 'link', active: false,  path: `${process.env.PUBLIC_URL}/calendar`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Connections', icon: Link2, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/connections`, access: { global_access: 0, partner_access: 1, client_access: 0 } 
        },
        {
            title: 'Development', icon: GitBranch, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/development`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Finance', icon: TrendingUp, type: 'sub', active: false, children: [
             { path: `${process.env.PUBLIC_URL}/finance/subscriptions`, type: 'link', title: 'Subscriptions', access: { global_access: 1, partner_access: 1, client_access: 1 } },
            ], access: { global_access: 1, partner_access: 1, client_access: 1 }
        },
        {
            title: 'Partners', icon: Briefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/partners`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Services', icon: Clipboard, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/services`, access: { global_access: 1, partner_access: 0, client_access: 0 } 
        },
        {
            title: 'Tools', icon: Zap, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/tools`, access: { global_access: 1, partner_access: 1, client_access: 0 } 
        },
        isGlobal || (emulationActive && dmtAccess.current === 1) ?
        /* The submenu for Admins */
        {
            title: 'Data Migration', icon: Tool, type: 'sub', active: false, children: [
                { path: `${process.env.PUBLIC_URL}/data-migration/records`, type: 'link', title: 'Record Search', access: { global_access: 1, partner_access: 1, client_access: 0 }  },
                { path: `${process.env.PUBLIC_URL}/tools/data-migration`, type: 'link', title: 'View Jobs', access: { global_access: 0, partner_access: 1, client_access: 0 }  }
            ], access: { global_access: 1, partner_access: 1, client_access: 0 }
        }
        :
        /* The submenu for partners */
        {
            title: 'Data Migration', icon: Tool, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/tools/data-migration`, access: { global_access: 0, partner_access: dmtAccess.current, client_access: 0 } 
        }
        ,
        {
            title: 'Users', icon: Users, type: 'sub', active: false, children: [
                { path: `${process.env.PUBLIC_URL}/users`, type: 'link', title: 'List Users', access: { global_access: 1, partner_access: 1, client_access: 0 }  },
                { path: `${process.env.PUBLIC_URL}/users/audit`, type: 'link', title: 'View Audit Log', access: { global_access: 1, partner_access: 1, client_access: 0 }  },
            ], access: { global_access: 1, partner_access: 1, client_access: 0 }
        }
    ]

    /**
     * Sort items alphabetically before adding the top
     * level items
     */
    // Items.sort((a,b) =>  a.title > b.title ? 1 : -1 )

    /**
     * For any menu items we want on top, we'll put them
     * at the start of the items array
     */
    Items.unshift(
        {
            title: 'Dashboard', icon: Home, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard`, access: { global_access: 1, partner_access: 1, client_access: 1 }
        }
    )

    return [
        {
            menutitle: '',
            menucontent: 'Dashboards,Widgets',
            Items: Items
        },
        {
            menutitle: '',
            menucontent: 'Dashboards,Widgets',
            Items: [
                {
                    title: 'Need Help?', icon: HelpCircle, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/help`, access: { global_access: 1, partner_access: 1, client_access: 1 }
                }
            ]
        }
    ]
}