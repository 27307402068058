import React, { Fragment, useContext, useEffect, useState } from 'react';
import TableBuilder, {ApiRequestBuilder, FormOptionsBuilder, RegisteredInputsBuilder, ModalButtonBuilder, ButtonBuilder } from '../../../../GlobalComponents/Builders';
import { buildOptionsFromArray } from '../../../../GlobalComponents/Helpers';
import { Container, Row, Card, CardBody, Form, CardFooter} from 'reactstrap'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AccountContext } from '../../../../GlobalComponents/Authentication/Accounts';
import OrderReport from './subcomponents/OrderReport';
import ActivationReport  from './subcomponents/ActivationReport';
import parse from 'html-react-parser';
import { Col } from 'react-bootstrap';

const WSReport = ({toolData}) => {

    const { userSession } = useContext(AccountContext);

    const [activePartnerSubscription, setActivePartnerSubscription] = useState(false);

    const [orderReport, setOrderReport] = useState(<OrderReport/>);
    const [activationReport, setActivationReport] = useState(false);

    useEffect(() => {

        if (Object.entries(toolData).length > 0)
        {
            toolData.subscriptions.forEach(subscription => {
                if (subscription.partner_id === Number(userSession['custom:partnerId']) && subscription.is_active)
                {
                    setActivePartnerSubscription(true)
                }
            })
        }

        if(orderReport){
            setActivationReport(false);
        }
        else if(activationReport){
            setOrderReport(false);
        }

    },[orderReport, activationReport]);

    const handleReport = (report) => {
        
        if (report == 'order'){
            setOrderReport(<OrderReport/>)
            setActivationReport(false)
        }
        else if(report == 'activation'){
            setActivationReport(<ActivationReport/>)
            setOrderReport(false)
        }
    }

    return(
        <Fragment>
            <Col xl="12">
                <Card>
                    <CardBody>
                        <Row>
                            <h4>{toolData.name}</h4>
                            <br/>
                            <h5>Description:</h5>
                            {toolData.html_description ? parse(toolData.html_description) : ''}
                        </Row>
                        <ButtonBuilder label='Order Report' className = 'm-2' color='secondary' onClick={() => handleReport('order')} />
                        <ButtonBuilder label='Activation Report' className = 'm-2' color='secondary' onClick={() => handleReport('activation')} />
                        {orderReport}
                        {activationReport}   
                    </CardBody>
                </Card>
            </Col>
        </Fragment>   
    )
};

export default WSReport;